// --------------------------------------------------
// base style
// --------------------------------------------------

.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 770px;
  flex-wrap: wrap;

  @media #{$mq-min-md} {
    flex-direction: row;
  }
}

// --------------------------------------------------
// nav
// --------------------------------------------------

.tabs__nav {
  display: block;
  order: 2;
  position: relative;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  align-self: flex-start;

  @media #{$mq-min-md} {
    width: 60%;
    order: 1;
  }

  .tab__name {
    position: relative;
    display: inline-block;
    width: 120px;
    padding: rem(10) 0;
    cursor: pointer;
    font-family: $font-family-primary;
    font-size: $font-size-sm;
    text-align: center;
    color: color('white');
    background-color: $link-color;
    margin-left: rem(10);
    text-transform: capitalize;

    &:first-child {
      margin-left: 0;
    }

    @media #{$mq-min-sm} {
      width: 200px;
      font-size: $font-size-h5;
    }

    &:hover {
      color: color('white');
      background: $link-color-hover;
      outline: 0;
    }

    & + li {
      margin-left: rem(5);
    }

    &--active,
    &--active:hover {
      cursor: default;
      color: $link-color;
      background-color: color('white');
    }
  }
}

// --------------------------------------------------
// content
// --------------------------------------------------

.tab__content {
  display: none;
  order: 3;
  margin: 0 0 rem(20);
  padding: rem(20);
  border: 0;
  width: 100%;
  background-color: color('white');

  &--active {
    display: block;
  }

  @media #{$mq-min-sm} {
    padding: rem(50);
  }
}
