// --------------------------------------------------
// base style
// --------------------------------------------------

.pull-up-wrapper {
  display: block;
  position: relative;
  width: 100%;
  z-index: 2;

  &::before {
    content: '';
    position: absolute;
    display: none;
    width: 100%;
    top: 0;
    left: 0;
    background-color: color('gray-lighter');
    z-index: -1;
    height: rem(130);

    @media #{$mq-min-sm} {
      display: block;
    }
  }
}
