// --------------------------------------------------
// Google fonts
// --------------------------------------------------

@import url('https://fonts.googleapis.com/css?family=Kulim+Park:700|Montserrat:400,500,600,700&display=swap');

// --------------------------------------------------
// Custom fonts
// --------------------------------------------------

$icomoon-font-family: 'good-vs-gourmet' !default;
$icomoon-font-path: '../../assets/fonts/' !default;

$wl-icon-arrow-right: '\e900';
$wl-icon-facebook: '\e901';
$wl-icon-twitter: '\e902';
$wl-icon-video-clapboard: '\e903';
$wl-icon-video-play-triangle-small: '\e904';
$wl-icon-youtube: '\e906';

@font-face {
  font-family: '#{$icomoon-font-family}';
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?h9etqd');
  src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?h9etqd#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?h9etqd')
      format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?h9etqd')
      format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?h9etqd##{$icomoon-font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='wl-icon-'],
[class*=' wl-icon-'],
.wl-icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wl-icon-arrow-right {
  &:before {
    content: $wl-icon-arrow-right;
    color: inherit;
  }
}
.wl-icon-facebook {
  &:before {
    content: $wl-icon-facebook;
    color: inherit;
  }
}
.wl-icon-twitter {
  &:before {
    content: $wl-icon-twitter;
    color: inherit;
  }
}
.wl-icon-video-clapboard {
  &:before {
    content: $wl-icon-video-clapboard;
    color: inherit;
  }
}
.wl-icon-video-play-triangle-small {
  &:before {
    content: $wl-icon-video-play-triangle-small;
    color: inherit;
  }
}
.wl-icon-youtube {
  &:before {
    content: $wl-icon-youtube;
    color: inherit;
  }
}
