// --------------------------------------------------
// breakpoints & media queries
// --------------------------------------------------

$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 771px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$mq-pr: 'print';

$mq-min-sm: '(min-width: #{$breakpoint-sm})';
$mq-min-md: '(min-width: #{$breakpoint-md})';
$mq-min-lg: '(min-width: #{$breakpoint-lg})';
$mq-min-xl: '(min-width: #{$breakpoint-xl})';

$mq-max-xs: '(max-width: #{$breakpoint-sm - 1px})';
$mq-max-sm: '(max-width: #{$breakpoint-md - 1px})';
$mq-max-md: '(max-width: #{$breakpoint-lg - 1px})';
$mq-max-lg: '(max-width: #{$breakpoint-xl - 1px})';

// --------------------------------------------------
// grid
// --------------------------------------------------

$grid-column-count: 12;
$grid-gutter-width: rem(30);

// --------------------------------------------------
// links
// --------------------------------------------------

$link-color: color('primary');
$link-color-hover: color('primary', 'hover');

// --------------------------------------------------
// globals
// --------------------------------------------------

$global-border-width: 1px;
$global-border-style: solid;
$global-border-color: color('gray-lighter');
$global-border: $global-border-width $global-border-style $global-border-color;
$global-border-radius: 0.25rem;

$global-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
$global-page-box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5);

$global-transition-fast: all 0.15s ease-in-out;
$global-transition-slow: all 0.3s ease-in-out;

// --------------------------------------------------
// nav
// --------------------------------------------------

$nav-desktop-height: rem(160);
$nav-desktop-height--sticky: rem(100);
$nav-mobile-height: rem(100);

// --------------------------------------------------
// z-indexes
// --------------------------------------------------

$z-index-modal: 1050;
$z-index-nav: 10;
$z-index-page-content: 2;
$z-index-footer: 1;

// --------------------------------------------------
// font color
// --------------------------------------------------

$font-color-base: color('gray');

// --------------------------------------------------
// font families
// --------------------------------------------------

$font-family-sans-serif: 'Kulim Park', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-primary: $font-family-sans-serif;
$font-family-secondary: 'Montserrat', -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-family-serif: Georgia, serif;
$font-family-monospace: Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;

// --------------------------------------------------
// font weight
// --------------------------------------------------

$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// --------------------------------------------------
// font size
// --------------------------------------------------

$font-size-base: 16px;

$font-size-xs: rem(12);
$font-size-sm: rem(14);
$font-size-md: rem($font-size-base);
$font-size-lg: rem(18);
$font-size-xl: rem(20);

$font-size-h1: rem(40);
$font-size-h2: rem(32);
$font-size-h3: rem(24);
$font-size-h4: rem(22);
$font-size-h5: rem(20);
$font-size-h6: rem(18);

// --------------------------------------------------
// line height
// --------------------------------------------------

$line-height-base: 1.85;
$line-height-headings: $line-height-base * 0.5;

// --------------------------------------------------
// type margin
// --------------------------------------------------

$type-margin-base: $line-height-base * 1rem;
